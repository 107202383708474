import React from "react";
import {Link} from 'react-router-dom';

import { ShoppingCartSimple } from "phosphor-react";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="nav-container">
        <Link to='/' className="brand-logo">
          MKR
        </Link>
        <div className="nav-items">
          <Link to='/'>Shop</Link>
          <Link to='/contact'>Contact</Link>
          <Link to='/cart'>
            <ShoppingCartSimple className='cart-icon' size={24}/>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
