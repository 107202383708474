import React, { useState, createContext } from "react";
import { Items } from "../Items";

const CustomContext = createContext(null);

const Shopcontext = ({ children }) => {
  const getDefaultCart = () => {
    const cart = {}; //object 
    for (let i = 1; i < Items.length + 1; i++) {
      cart[i] = 0; // brackets notation of object not an array
    }
    return cart;
  };

  const [cartItems, setCartItems] = useState(getDefaultCart());

  const updateQty = (itemId,value)=>{
    setCartItems((prev)=>{
      return {...prev,[itemId]:value};
    })
  }

  const addCart = (itemId) => {
      setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1}));
  };

  const removeItem = (itemId) => {
    if (cartItems[itemId] > 0) {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    }
  };

  const contextValues = { cartItems, addCart, updateQty, removeItem };

  return (
    <CustomContext.Provider value={contextValues}>
      {children}
    </CustomContext.Provider>
  );
};

export { CustomContext, Shopcontext };
