import React, { useContext } from "react";
import { CustomContext } from "../context/Shopcontext";

import Styles from "../pages/page.module.css";

function Products({ item, origin }) {
  const { cartItems, addCart, updateQty, removeItem } = useContext(CustomContext);

  const handleQtyUpdate = (e)=>{
    updateQty(item.id,e);
  }
  
  
  return (
    <div>
      <img src={item.productImage} alt="product img"></img>
      <p className={Styles["product-name"]}>{item.productName}</p>
      <p className={Styles["pricing"]}>$ {item.price}</p>

      {origin === "cart" ? (
        <button className={Styles["add-cart"]}>
          Qty: 
          <input value={cartItems[item.id]} onChange={(e)=>{handleQtyUpdate(Number(e.target.value))}} />
        </button>
      ) : (
        <button className={Styles["add-cart"]} onClick={() => addCart(item.id)}>
          Add to Cart - {cartItems[item.id]}
        </button>
      )}

      <button
        className={Styles["add-cart"]}
        onClick={() => removeItem(item.id)}
      >
        Remove from cart
      </button>
    </div>
  );
}

export default Products;
