import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Products from "../../components/Products";
import { CustomContext } from "../../context/Shopcontext";
import { Items } from "../../Items";

import Styles from '../page.module.css';

function Cart() {
  const { cartItems } = useContext(CustomContext);
  const navigate = useNavigate();

  return (
    <div className="cart">
      <h1 className="main-heading">Items in your Cart:</h1>

      <div className={Styles['grid-3-col']}>
       {Items.map(item => {
        if(cartItems[item.id]!==0){
         return <Products key={item.id} item={item} origin={'cart'} /> }
         return null;
       })
      }
      </div>
      <div className={Styles['cart-btns']}>
      <button className={Styles['black-btn']} onClick={()=>{navigate('/checkout')}}>Checkout</button>
      <button className={Styles['black-btn']} onClick={()=>{navigate('/')}}> Continue Shopping</button>
      </div>   
    </div>
  );
}

export default Cart;
