import iPhone from './assets/products/1.png';
import Macbook from './assets/products/2.png';
import Canon from './assets/products/3.png';
import Jacket from './assets/products/4.png';
import LED from './assets/products/5.png';
import Tees from './assets/products/6.webp';
import Shirt from './assets/products/7.webp';
import Hat from './assets/products/8.webp';

export const Items = [
    {
        id:1,
        productName:'iPhone',
        productImage:iPhone,
        price:540,
    },
    {
        id:2,
        productName:'MacBook Pro M1',
        productImage:Macbook,
        price:1200,
    },
    {
        id:3,
        productName:'Canon EOS',
        productImage:Canon,
        price:2500,
    },
    {
        id:4,
        productName:'Mens Jacket',
        productImage:Jacket,
        price:60,
    },
    {
        id:5,
        productName:'LED Strips',
        productImage:LED,
        price:10.87,
    },
    {
        id:6,
        productName:'Full Sleeve T-Shirt ',
        productImage:Tees,
        price:52,
    },
    {
        id:7,
        productName:'Shirt Men',
        productImage:Shirt,
        price:40,
    },
    {
        id:8,
        productName:'Brown Cap',
        productImage:Hat,
        price:18,
    },
];