import React from "react";

import { Items } from "../../Items";
import Products from "../../components/Products";

import Styles from '../page.module.css';

function Shop() {
  return (
    <div className="shop">
      <h1 className="main-heading">MKR SHOP</h1>

      <div className={Styles['grid-3-col']}>
        {" "}
        {Items.map((item) => (
          <Products key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default Shop;
