import { useContext } from "react";

import { Items } from "../../Items";
import { CustomContext } from "../../context/Shopcontext";
import Styles from "../page.module.css";

const Checkout = () => {
  console.log("checkout");
  const { cartItems } = useContext(CustomContext);
  console.log(cartItems);

  return (
    <div className={Styles["checkout-wrapper"]}>
      {Items.map((item) => {
        if (cartItems[item.id] !== 0) {
            console.log(cartItems[item.id]);
          return (
            <div key={item.id} className={Styles['checkout-listing']}>
              <img
                alt={item.productName}
                src={item.productImage}
              />
              <div>
              <h3>{item.productName}</h3>
              <p>Price : $ {item.price}</p>
              <p>Qty : {cartItems[item.id]}</p>
              <p>Total: $ {cartItems[item.id]*item.price} </p>
              </div>
            </div>
          );
        }
        else{
          return null;
        }
      }
      )}
    </div>
  );
};

export default Checkout;
