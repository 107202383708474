import React from 'react';

function Contact() {
  return (
    <div>
      <h1 className='main-heading'>Contact</h1>
      </div>
  )
}

export default Contact;